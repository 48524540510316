import { mapGetters } from 'vuex';
import { formatTwoDate } from '@/utils/base64Util';
import { getHash } from '@/utils/api';

import getBannerImage from '@/mixins/getBannerImage';

export default {
  name: 'TemplateSearchResult',
  data() {
    return {
      activeDestDates: '',
      listDests: [],
      hotelsByDestDate: [],
      isLoadingMulti: true,
      tabIndex: 0,
      hotels: [],
      filteredHotel: [],
      query: null,
      searchResultCount: 0,
      // bannerImages: [],
      exCode: '',
      // imagesData: [],
      filterOptions: {},
      filterPriceValues: [],
      completedFilteredHotelOnly: [],
      // sortButtonList: ['priceLTH', 'priceHTL', 'chainSelect'],
      // mobileSortButtonList: ['priceLTH', 'priceHTL', 'chainSelect'],
      sortButtonList: ['priceLTH', 'priceHTL'],
      mobileSortButtonList: ['priceLTH', 'priceHTL'],
      countDown: 40,
      timerEnd: false,
      sortedReset: true,

      /**
       * Multi-dates part
       */
      entireSearchDates: [],
      listDateKeys: [],
    };
  },
  mixins: [getBannerImage],
  computed: {
    ...mapGetters({
      packagesMultiDest: 'GET_PACKAGES',
      destinationList: 'GET_DOMESTIC_DESTINATION',
      isLoading: 'GET_LOADING_STATE',
      lang: 'GET_LANGUAGE',
      isRTLPage: 'GET_IS_RLT_PAGE',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      hideCondition: 'GET_HIDE_CONDITION',
      hotelConversionList: 'GET_DOMESTIC_HOTELS',
      hotelChain: 'GET_HOTEL_CHAIN_TABLE',
      marketerId: 'GET_MARKETER_ID',
      agencyMarketerContent: 'GET_MARKETER_AGENCY_CONTENT',
      device: 'GET_DEVICE',
      agencyOwnerName: 'GET_AGENCY_OWNER_NAME',
      affiliation: 'GET_AFFILIATION',
      isMultiSearchDate: 'GET_MULTI_SEARCH_DATES_STATE',
      isMultiSearchCase: 'GET_IS_MULTIPLE_SEARCH_CASE',
      isEmalonMode: 'GET_IS_EMALON_MODE',
    }),
    dateString() {
      const { tabIndex, entireSearchDates, isMultiSearchDate } = this;
      if (!entireSearchDates || !entireSearchDates.length) return '';
      const from = isMultiSearchDate ? entireSearchDates[tabIndex].from : entireSearchDates[0].from;
      const to = isMultiSearchDate ? entireSearchDates[tabIndex].to : entireSearchDates[0].to;
      return formatTwoDate(from, to);
    },
    hotelNameFilterOption() {
      const { hotels } = this;
      if (!hotels || !hotels.length) return null;
      const noneDupHotelNames = [...new Set(hotels.map((item) => (item.name)).sort((a, b) => (a < b ? -1 : 1)))];
      const hotelNames = [...noneDupHotelNames.map((item) => ({ label: item }))];
      return {
        kind: 'hotel-name',
        title: this.$t('search-result.hotels'),
        subItems: hotelNames,
      };
    },
    hotelBasisFilterOption() {
      const { hotels } = this;
      if (!hotels || !hotels.length) return null;
      let noneDupBasisNames = [...new Set(hotels.map((item) => (item.basisName)))];
      noneDupBasisNames = noneDupBasisNames.filter((el) => el != null);
      const hotelBasis = [...noneDupBasisNames.map((item) => ({ label: item }))];
      return {
        kind: 'hotel-basis',
        title: this.$t('search-result.hosting-base'),
        subItems: hotelBasis,
      };
    },
    hotelChainListOption() {
      const { hotelChain, hotels } = this;
      if (!hotels) return {};
      const chainIds = hotels.map((h) => h.hotelChainId);
      const chains = hotelChain.filter((chain) => chainIds.includes(chain.id)).map((chain) => ({ value: chain.id, text: chain.name }));
      chains.unshift({ value: null, text: this.$t('search-result.not-set') });
      chains.unshift({ value: 'all', text: this.$t('search-result.all') });
      return {
        kind: 'chain',
        title: this.$t('search-result.chain-filter'),
        subItems: chains,
        defaultSelect: 'all',
      };
    },
    hideFooter() {
      const hide = this.hideCondition || (this.marketerId !== '' && this.agencyMarketerContent?.marketerSpecificContents?.hideFooter);
      return hide;
    },
    allDestinationCityName() {
      let strDests = '';
      const destCodes = this.query.city.split(',');
      destCodes.forEach((dest, idx) => {
        strDests += this.destinationCityName(dest);
        if (idx < destCodes.length - 1) strDests += ', ';
      });
      return strDests;
    },
    firstDest() {
      return this.listDests[0];
    },
  },
  watch: {
    packagesMultiDest: 'getHotels',
    activeDestDates: 'setHotels',
    $route: 'changedRoute',
    hotelConversionList: 'fetchData',
    filterPriceValues() {
      this.applyPriceRangeFilter();
    },
  },
  created() {
    this.$store.commit('SET_CURRENT_PAGE', 'search-result');
    this.setSearchStatus();
    this.fetchData();
    this.countDownTimer();
  },
  async mounted() {
    this.$root.$on('setTimer', () => {
      this.setCountDown();
    });
  },
  methods: {
    setSearchStatus() {
      this.$store.commit('SET_CURRENT_PAGE', 'search-result');

      this.query = this.$route.query;
      this.exCode = this.query.exCode;
      localStorage.setItem('search-query', JSON.stringify(this.query));
    },
    changedRoute() {
      this.setSearchStatus();
      this.fetchData();
    },
    async fetchData() {
      if (!this.hotelConversionList?.data || this.hotelConversionList.data.length === 0) return;
      this.countDown = 40;
      if (this.timerEnd) this.countDownTimer();
      this.scrollToItems();
      this.completedFilteredHotelOnly = [];
      this.query = this.$route.query;

      if (!this.query.city || !this.query.checkIn || !this.query.checkOut || !this.query.adult || !this.query.suppliers) {
        this.$bvModal.msgBoxOk(
          this.$t('search-result.dont-remove-query'),
          {
            title: this.$t('product-page.expire-title'),
            dialogClass: 'noSearchResult',
            okVariant: 'success',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          },
        );
        return;
      }
      /**
       * Multiple search date part.
       */
      const checkInList = this.query.checkIn.split(',');
      const checkOutList = this.query.checkOut.split(',');

      if (checkInList.length !== checkOutList.length) {
        this.$bvModal.msgBoxOk(
          this.$t('search-result.dont-remove-query'),
          {
            title: this.$t('product-page.expire-title'),
            dialogClass: 'noSearchResult',
            okVariant: 'success',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          },
        );
        return;
      }

      this.listDests = this.query.city.split(',');
      const allHotelCodes = this.query.hotelCode.split(',');
      const hotelCities = {};
      this.listDests.forEach((cityCode) => {
        const hotelCodesCity = this.hotelConversionList?.data?.filter((hotelItem) => hotelItem.mainCityCode === cityCode).reduce((mergedCodes, currItem) => {
          mergedCodes.push(currItem.hotelAtlantisCode, currItem.hotelExternalCode);
          return mergedCodes;
        }, []);
        const hotelCodes = [];
        allHotelCodes.forEach((hotelCode) => {
          if (hotelCodesCity.includes(hotelCode)) hotelCodes.push(hotelCode);
        });
        hotelCities[cityCode] = [...hotelCodes];
      });

      this.$store.commit('SET_PACKAGES', {});
      this.activeDestDates = '';
      this.isLoadingMulti = true;
      const entriesHotelCity = Object.entries(hotelCities);

      this.entireSearchDates = checkInList.map((checkIn, inx) => ({
        from: checkIn,
        to: checkOutList[inx],
      }));
      this.listDateKeys = this.entireSearchDates.map((d) => (formatTwoDate(d.from, d.to)));

      if (this.entireSearchDates.length > 1) {
        await Promise.all(this.entireSearchDates.map(async (searchDate) => {
          const body = {
            multiSearchDatesState: true,
            city: entriesHotelCity[0][0],
            hotelCode: entriesHotelCity[0][1].join(','),
            checkIn: searchDate.from,
            checkOut: searchDate.to,
            adult: Number(this.query.adult),
            child: Number(this.query.child),
            infant: Number(this.query.infant),
            lang: this.query.lang,
            includeFlight: this.query.includeFlight,
            suppliers: this.query.suppliers.split(','), // ['GOC', 'MINI', 'DAN', 'ISRO', 'ATLANTIS'],
            sources: this.query.sources,
            returnLog: this.query.returnLog,
          };
          return this.$store.dispatch('FETCH_PACKAGE_BY_SEARCH', body);
        }))
          .then(() => {
            this.activeDestDates = this.listDateKeys[0];
            this.isLoadingMulti = false;
            this.timerEnd = true;
            this.countDown = -1;
          });
      } else {
        await Promise.all(entriesHotelCity.map(async ([city, hotels]) => {
          const body = {
            multiSearchDatesState: false,
            city,
            hotelCode: hotels.join(','),
            checkIn: this.entireSearchDates[0].from,
            checkOut: this.entireSearchDates[0].to,
            adult: Number(this.query.adult),
            child: Number(this.query.child),
            infant: Number(this.query.infant),
            lang: this.query.lang,
            includeFlight: this.query.includeFlight,
            suppliers: this.query.suppliers.split(','), // ['GOC', 'MINI', 'DAN', 'ISRO', 'ATLANTIS'],
            sources: this.query.sources,
            returnLog: this.query.returnLog,
          };
          return this.$store.dispatch('FETCH_PACKAGE_BY_SEARCH', body);
        }))
          .then(() => {
            this.activeDestDates = this.listDests[0];
            this.isLoadingMulti = false;
            this.timerEnd = true;
            this.countDown = -1;
          });
      }
      this.tabIndex = 0;
      this.sendSearchData(checkInList, checkOutList);

      // const body = {
      //   city: dests[0],
      //   hotelCode: this.query.hotelCode,
      //   checkIn: this.query.checkIn,
      //   checkOut: this.query.checkOut,
      //   adult: Number(this.query.adult),
      //   child: Number(this.query.child),
      //   infant: Number(this.query.infant),
      //   lang: this.query.lang,
      //   includeFlight: this.query.includeFlight,
      //   suppliers: this.query.suppliers.split(','), // ['GOC', 'MINI', 'DAN', 'ISRO', 'ATLANTIS'],
      //   sources: this.query.sources,
      //   returnLog: this.query.returnLog,
      // };

      // await this.$store.dispatch('FETCH_PACKAGE_BY_SEARCH', body);
    },
    destinationCityName(pDest) {
      const dests = [pDest];
      const filterArray = this.destinationList.filter((item) => dests.includes(item.code));

      return filterArray.length > 0 ? filterArray[0].name : '';
    },
    getUsableHeight() {
      // check if this page is within a app frame
      const isInAppMode = ('standalone' in navigator && navigator.standalone) || (window.chrome && window.top.chrome.app && window.top.chrome.app.isInstalled);

      const ua = navigator.userAgent;
      // memoized values
      const isIphone = ua.indexOf('iPhone') !== -1 || ua.indexOf('iPod') !== -1;
      const isIpad = ua.indexOf('iPad') !== -1;
      const isAndroid = ua.indexOf('Android') !== -1;
      const isMobile = isIphone || isIpad || isAndroid;

      // compute the missing area taken up by the header of the web browser to offset the screen height
      let usableOffset = 0;
      if (isIphone) {
        usableOffset = 20;
      } else if (isAndroid && ua.indexOf('Chrome') === -1) {
        usableOffset = 1;
      }
      if (!isMobile) {
        const bannerArea = document.querySelector('.banner-area') || document.querySelector('.innerbanner_area');
        return bannerArea?.offsetHeight;
      }
      const isLandscape = window.innerWidth > window.innerHeight;
      let height;
      // on ios devices, this must use screen
      if (isIphone) {
        height = isLandscape ? window.screen.width : window.screen.height;
        if (!isInAppMode) {
          height -= isLandscape ? 32 : 44;
          height += 1;
        }
      } else {
        height = (isLandscape ? window.outerWidth : window.outerHeight) / (window.devicePixelRatio || 1);
      }
      return height - usableOffset;
    },
    scrollToItems() {
      setTimeout(() => {
        document.querySelector('.st-content').scrollTo(0, this.getUsableHeight());
      }, 1000);
    },
    changeTab(pIdx) {
      this.tabIndex = pIdx;
      this.activeDestDates = (this.isMultiSearchDate) ? this.listDateKeys[pIdx] : this.listDests[pIdx];
    },
    getHotels() {
      const entriesDestPack = Object.entries(this.packagesMultiDest);
      if (entriesDestPack.length === 0) return;
      this.hotelsByDestDate = entriesDestPack.map(([key, packages]) => {
        const hotels = [];
        if (packages && packages.length > 0) {
          packages.forEach((item) => {
            if (item.data.data === null) item.data.data = [];

            item.data.data.forEach((elm) => {
              elm.supplierCode = item.supplierCode;
              elm.adminRemark = (item.hotelInfo[elm.exCode]) ? item.hotelInfo[elm.exCode].adminRemark : '';
              elm.agentCommPctFromRule = (item.hotelInfo[elm.exCode]) ? item.hotelInfo[elm.exCode].agentCommPctFromRule : null;
              elm.fcCommPctFromRule = (item.hotelInfo[elm.exCode]) ? item.hotelInfo[elm.exCode].fcCommPctFromRule : 0;
              elm.rateRemarkForAgent = (item.hotelInfo[elm.exCode]) ? item.hotelInfo[elm.exCode]?.rateRemarkForAgent?.he || '' : '';

              elm.isSpecialDiscounting = elm.matchingDiscInfo?.matchingDiscRuleInfo?.isSpecialDiscounting || false;

              elm.imgUrl = (item.hotelInfo[elm.exCode]) ? item.hotelInfo[elm.exCode].imageUrlInHotelConv : '';
              // eslint-disable-next-line no-nested-ternary
              elm.imgUrl = (elm.imgUrl) ? elm.imgUrl
                : (item.hotelInfo[elm.exCode]?.allImageUrlsInHotelInfo != null
                && item.hotelInfo[elm.exCode].allImageUrlsInHotelInfo.length > 0
                  ? item.hotelInfo[elm.exCode].allImageUrlsInHotelInfo[0] : '');
              elm.hotelInfo = (item.hotelInfo[elm.exCode]) ? item.hotelInfo[elm.exCode] : null;
              elm.hotelChainId = this.hotelConversionList?.data?.find((ht) => ht.id === elm?.hotelInfo?.hotelId)?.hotelChainId || null;
              if (this.hotelConversionList) {
                const hotelIndex = this.hotelConversionList.data.findIndex((ht) => ht.hotelExternalCode === elm.exCode);
                if (hotelIndex > -1) {
                  elm.name = this.hotelConversionList.data[hotelIndex].hotelName;
                }
              }
            });

            let groupedHotels = {};
            if (this.exCode) {
              /**
               * This is for second search result page for not agent site.
               * In this case, show all.
               */
              const hotelList = item.data.data.filter((elem) => elem.exCode === this.exCode);
              hotels.push(...hotelList);
            } else if (this.isOdysseySite && item.supplierCode === 'ATLANTIS') {
              /**
               * If agent site and ATLANTIS hotel, grouped by hotel code and category
               */
              item.data.data.forEach((elm) => {
                const { exCode, category } = elm;
                elm.priority = (item.hotelInfo[exCode]) ? item.hotelInfo[exCode].priority : Infinity;
                if (groupedHotels[`${exCode}-${category}`]) {
                  groupedHotels[`${exCode}-${category}`].push(elm);
                } else {
                  groupedHotels[`${exCode}-${category}`] = [];
                  groupedHotels[`${exCode}-${category}`].push(elm);
                }
              });

              // eslint-disable-next-line no-restricted-syntax
              for (const value of Object.entries(groupedHotels)) {
                const sortedArray = this.sortArray(value[1], 'totalAfterDiscounted');
                sortedArray[0].count = sortedArray.length;
                if (sortedArray[0]) {
                  sortedArray[0].moreHotels = sortedArray; // .slice(1);
                  hotels.push(sortedArray[0]);
                }
              }
            } else if (!this.isOdysseySite && item.supplierCode === 'ATLANTIS') {
              /**
               * if not agent site and ATLANTIS hotel, don't grouped
               */
              groupedHotels = item.data.data.map((gh) => ({ ...gh, moreHotels: [gh] }));
              if (groupedHotels) hotels.push(...groupedHotels);
            } else {
              /**
               * If else case, grouped by hotel code only
               */
              item.data.data.forEach((elm) => {
                const { exCode } = elm;
                elm.priority = (item.hotelInfo[exCode]) ? item.hotelInfo[exCode].priority : Infinity;
                if (groupedHotels[exCode]) {
                  groupedHotels[exCode].push(elm);
                } else {
                  groupedHotels[exCode] = [];
                  groupedHotels[exCode].push(elm);
                }
              });

              // eslint-disable-next-line no-restricted-syntax
              for (const value of Object.entries(groupedHotels)) {
                const sortedArray = this.sortArray(value[1], 'totalAfterDiscounted');
                sortedArray[0].count = sortedArray.length;
                if (sortedArray[0]) {
                  sortedArray[0].moreHotels = sortedArray; // .slice(1);
                  hotels.push(sortedArray[0]);
                }
              }
            }
          });
        }
        return { key, hotels };
      });
      // this.searchResultCount = hotels.length;

      if (!this.exCode && !this.isOdysseySite) {
        /**
         * When first search result, send a event to GA
         */
        const gaItems = this.hotelsByDestDate[0].hotels.map((hotel) => ({
          item_id: hotel.exCode,
          item_name: hotel.name,
          affiliation: this.affiliation,
          item_brand: this.destinationCityName,
          item_category: `${this.query.checkIn} - ${this.query.checkOut}`,
          item_list_name: `search results to ${this.destinationCityName} ${this.query.checkIn} - ${this.query.checkOut}`,
          item_variant: 'HOTEL',
          price: hotel.totalAfterDiscounted,
        }));

        this.$store.dispatch('SENDING_GA_EVENT', {
          eventName: 'view_item_list',
          itemListId: getHash(`${this.query.city} ${this.query.checkIn} ${this.query.checkOut}`),
          itemListName: `${this.destinationCityName} ${this.query.checkIn} ${this.query.checkOut} - ${this.agencyOwnerName}`,
          items: gaItems,
        });
      }
      this.searchResultCount = this.hotelsByDestDate[0].hotels.length;
      // hotelInfo.{item}.discountPercent       ---> general discount
      // hotelInfo.{item}.isSpecialDiscounting  ---> special discount (yes/no)

      // data.data.discountPercent              ---> deal discount
      // data.data.isDealDiscounting            ---> deal discount (yes/no)
      // this.filteredHotel = hotels;
    },
    setHotels() {
      if (this.activeDestDates === '' || this.activeDestDates === undefined) return;
      const idxKey = this.hotelsByDestDate.findIndex((item) => item.key === this.activeDestDates);

      if (idxKey === -1) return;
      this.isLoadingMulti = true;
      setTimeout(() => {
        this.searchResultCount = this.hotelsByDestDate[idxKey].hotels.length;
        this.filteredHotel = this.hotelsByDestDate[idxKey].hotels;

        if (this.hotelsByDestDate[idxKey].hotels.length === 0) {
          this.$bvModal.msgBoxOk(
            `${this.$t('search-result.domestic-search.empty-hotel1')},
            ${this.isMultiSearchDate ? this.activeDestDates : this.destinationCityName(this.activeDestDates)}
            ${this.$t('search-result.domestic-search.empty-hotel2')}`,
            {
              title: this.$t('product-page.expire-title'),
              dialogClass: 'noSearchResult',
              okVariant: 'success',
              // bodyClass: this.lang === 'en' ? 'ltr' : 'text-right',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true,
            },
          );
        }
        this.filteredHotel = this.sortTotalHotel(this.hotelsByDestDate[idxKey].hotels);

        this.hotels = [...this.filteredHotel];
        this.updatePriceRangeFilterData();
        this.isLoadingMulti = false;
      }, 5);
    },
    sortArray(arr, sortItem, order = 1) {
      arr.sort((a, b) => (Number(a[sortItem]) - Number(b[sortItem])) * order);
      return arr;
    },
    sortTotalHotel(arr) {
      const atlantisHotel = arr.filter((elm) => elm.supplierCode === 'ATLANTIS');
      const dealDiscountedHotels = arr.filter((elm) => elm.supplierCode !== 'ATLANTIS' && (elm.isDealDiscounting || elm.isSpecialDiscounting));
      const generalDiscountedHotels = arr.filter((elm) => elm.supplierCode !== 'ATLANTIS' && !elm.isDealDiscounting && !elm.isSpecialDiscounting && elm.discountPercent);
      const restHotels = arr.filter((elm) => elm.supplierCode !== 'ATLANTIS' && !elm.discountPercent);

      return [
        ...this.sortArray(atlantisHotel, 'discountPercent', -1),
        ...this.sortArray(this.sortByHotIcon(dealDiscountedHotels), 'totalAfterDiscounted'),
        ...this.sortArray(this.sortByHotIcon(generalDiscountedHotels), 'totalAfterDiscounted'),
        ...this.sortArray(restHotels, 'totalAfterDiscounted'),
      ];
    },
    sortByHotIcon(arr) {
      arr.sort((a, b) => {
        if ((a.isDealDiscounting && !!a.discountPercent) || !!a.isSpecialDiscounting) {
          return -1;
        } else if ((b.isDealDiscounting && !!b.discountPercent) || !!b.isSpecialDiscounting) {
          return 1;
        }
        return 0;
      });
      return arr;
    },
    updatePriceRangeFilterData() {
      let priceList = [];
      if (this.filteredHotel) {
        priceList = this.filteredHotel.length > 0 ? this.getPriceList(this.filteredHotel, 'totalAfterDiscounted') : [];
      }
      if (priceList.length > 1) {
        const minPrice = Number(priceList[0]);
        const maxPrice = Number(priceList[priceList.length - 1]);

        const priceFilter = {
          kind: 'slider',
          title: 'price',
          value: [minPrice, maxPrice],
          min: minPrice,
          max: maxPrice,
          marks: priceList,
          symbol: this.filteredHotel[0].cc,
        };
        this.$set(this.filterOptions, 'priceSlider', priceFilter);
        this.filterPriceValues = [minPrice, maxPrice];

        this.priceFilterItem = priceFilter;
      } else {
        this.$set(this.filterOptions, 'priceSlider', null);
      }

      this.$set(this.filterOptions, 'chain', this.hotelChainListOption);
      if (!this.isEmalonMode) {
        this.$set(this.filterOptions, 'hotelBasis', this.hotelBasisFilterOption);
      }
      this.$set(this.filterOptions, 'hotelName', this.hotelNameFilterOption);
    },
    applyPriceRangeFilter() {
      const [minPrice, maxPrice] = this.filterPriceValues;
      const result = this.hotels.filter(
        (item) => item.totalAfterDiscounted >= minPrice && item.totalAfterDiscounted <= maxPrice,
      );
      this.filteredHotel = result;
    },
    getPriceList(arr, prop) {
      const prices = arr.map((item) => item[prop]);
      return [...new Set(prices)].sort((a, b) => a - b);
    },
    async filterByOption(conditions) {
      this.sortedReset = false;
      let { hotels } = this;

      if (conditions.price) {
        this.filterPriceValues = conditions.price;
        await this.applyPriceRangeFilter();
        hotels = this.filteredHotel;
      }
      const result = hotels.filter((item) => {
        const cond1 = (conditions['hotel-name'] && conditions['hotel-name'].length)
          ? conditions['hotel-name'].indexOf(item.name) > -1
          : true;
        const cond2 = (conditions['hotel-basis'] && conditions['hotel-basis'].length)
          ? conditions['hotel-basis'].indexOf(item.basisName) > -1
          : true;
        const cond3 = (conditions.chain === undefined || conditions.chain === 'all')
          ? true
          : item.hotelChainId === conditions.chain;
        return cond1 && cond2 && cond3;
      });
      this.filteredHotel = result;
      this.searchResultCount = this.filteredHotel.length;
      document.querySelector('.st-content').scrollTo(0, this.getUsableHeight());
      this.scrollToItems();
      setTimeout(() => {
        this.sortedReset = true;
      }, 50);
    },
    doSort(arr) {
      this.completedFilteredHotelOnly = [];
      this.applySort(arr[0], arr[1]);
    },
    applySort(type, asc) {
      this.sortedReset = false;
      let sortArray = [];
      sortArray = this.filteredHotel || [];

      if (type === 'price') {
        if (asc) {
          sortArray.sort((a, b) => a.totalAfterDiscounted - b.totalAfterDiscounted);
        } else {
          sortArray.sort((a, b) => b.totalAfterDiscounted - a.totalAfterDiscounted);
        }
      } else if (type === 'chain') {
        this.filterByOption({ chain: asc });
      }
      setTimeout(() => { this.sortedReset = true; }, 50);
    },
    countDownTimer() {
      if (this.countDown > 0) {
        this.timerEnd = false;
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.timerEnd = true;
      }
    },
    setCountDown() {
      this.countDown = 40;
    },
    sendSearchData(checkIns, checkOuts) {
      const body = {
        destCity: [
          {
            code: this.listDests[0],
            name: this.destinationCityName(this.listDests[0]),
          },
        ],
        hotel: null,
        reservationPeriod: [
          {
            checkin: checkIns[0],
            checkout: checkOuts[0],
          },
        ],
        adults: +this.query.adult,
        children: +this.query.child,
        infant: +this.query.infant,
      };
      this.$store.dispatch('JOURNEY_SEARCH_HOTELS', { body });
    },
  },
};
